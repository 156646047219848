import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import React, { ReactNode } from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { MAX_Z_INDEX_VALUE } from '../../utils/z-index';
import { Box } from '../Box';
import { Separator } from '../Separator';
import { Text } from '../Text';

import { DropdownMenuButton } from './DropdownMenuButton';

const PU_DROPDOWN_MENU_CONTENT_OFFSET = 4;
const PU_DROPDOWN_MENU_MIN_WIDTH = 130;

const DropdownMenuTrigger: React.FC<React.PropsWithChildren<{ children: React.ReactElement }>> = ({
  children,
}) => <DropdownMenuPrimitive.Trigger asChild>{children}</DropdownMenuPrimitive.Trigger>;

export interface DropdownMenuProps extends DropdownMenuPrimitive.DropdownMenuProps {
  children: React.ReactNode;
}

const DropdownMenuComponent = ({ children, ...props }: DropdownMenuProps) => {
  return (
    // TODO: Known bug where menu reopens when clicking the trigger to close.
    // Waiting on https://github.com/radix-ui/primitives/issues/1059
    <DropdownMenuPrimitive.Root modal={false} {...props}>
      {children}
    </DropdownMenuPrimitive.Root>
  );
};

const StyledRadixContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: PU_DROPDOWN_MENU_MIN_WIDTH,
  backgroundColor: '$bgDefault',
  borderRadius: '$radius1',
  border: '1px solid $borderLoud',
  padding: '$space1 $space0',
  boxShadow: '$shadow2',
  overflow: 'hidden',
  fontSize: '$fontSize3',
  zIndex: MAX_Z_INDEX_VALUE,
});

const StyledRadixSubContent = styled(DropdownMenuPrimitive.SubContent, StyledRadixContent);

const StyledItem = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  padding: '$space2 $space3',
  cursor: 'pointer',
  color: '$textDefault',

  '&[data-disabled]': {
    color: '$textDisabled',
    cursor: 'not-allowed',
  },

  '&:focus': {
    outline: 'none',
    backgroundColor: '$bgRowHover',
  },
});

const StyledRadixItem = styled(DropdownMenuPrimitive.Item, StyledItem, {
  '&:active:focus': {
    outline: 'none',
    backgroundColor: '$bgRowPressed',
  },
});

const StyledRadixTextItem = styled(StyledRadixItem, Text);

// Item which can be used as a trigger for a nested dropdown.
const SubMenuTriggerItem = styled(DropdownMenuPrimitive.SubTrigger, StyledItem);

const StyledRadixLabel = styled(DropdownMenuPrimitive.Label, {
  padding: '$space2 $space3',
  color: '$grayscale700',
});

const DropdownMenuSeparator = () => {
  return <Separator css={{ margin: '$space2 $space0' }} />;
};

const DropDownMenuLabel: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  if (typeof children === 'string') {
    return <StyledRadixLabel>{children}</StyledRadixLabel>;
  }
  return <StyledRadixLabel asChild>{children}</StyledRadixLabel>;
};

const DropdownMenuTextItem = ({
  children,
  ...rest
}: React.ComponentProps<typeof StyledRadixTextItem>) => (
  <StyledRadixTextItem {...rest}>{children}</StyledRadixTextItem>
);

interface DropdownMenuContentProps extends DropdownMenuPrimitive.DropdownMenuContentProps {
  children: ReactNode;
  css?: PicnicCss;
}

const DropdownMenuContent = ({
  children,
  align = 'start',
  css,
  ...rest
}: DropdownMenuContentProps) => (
  <DropdownMenuPrimitive.Portal>
    <StyledRadixContent
      sideOffset={PU_DROPDOWN_MENU_CONTENT_OFFSET}
      align={align}
      css={css}
      {...rest}
    >
      {children}
    </StyledRadixContent>
  </DropdownMenuPrimitive.Portal>
);

interface DropdownSubMenuContentProps extends DropdownMenuPrimitive.DropdownMenuSubContentProps {
  children: ReactNode;
  css?: PicnicCss;
}

const DropdownMenuSubContent = ({ children, css, ...rest }: DropdownSubMenuContentProps) => (
  <DropdownMenuPrimitive.Portal>
    <StyledRadixSubContent sideOffset={PU_DROPDOWN_MENU_CONTENT_OFFSET} css={css} {...rest}>
      {children}
    </StyledRadixSubContent>
  </DropdownMenuPrimitive.Portal>
);

type ComponentType = typeof DropdownMenuComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Trigger: typeof DropdownMenuTrigger & DisplayNamed;
  SubMenuTriggerItem: typeof SubMenuTriggerItem & DisplayNamed;
  Content: typeof DropdownMenuContent & DisplayNamed;
  SubContent: typeof DropdownMenuSubContent & DisplayNamed;
  Sub: typeof DropdownMenuPrimitive.Sub & DisplayNamed;
  Button: typeof DropdownMenuButton & DisplayNamed;
  Label: typeof DropDownMenuLabel & DisplayNamed;
  Item: typeof StyledRadixItem & DisplayNamed;
  TextItem: typeof DropdownMenuTextItem & DisplayNamed;
  Separator: typeof DropdownMenuSeparator & DisplayNamed;
  UnstyledItem: typeof DropdownMenuPrimitive.Item & DisplayNamed;
}

const DropdownMenu = DropdownMenuComponent as CompositeComponent;
DropdownMenu.Trigger = DropdownMenuTrigger;
DropdownMenu.SubMenuTriggerItem = SubMenuTriggerItem;
DropdownMenu.Content = DropdownMenuContent;
DropdownMenu.SubContent = DropdownMenuSubContent;
DropdownMenu.Sub = DropdownMenuPrimitive.Sub;
DropdownMenu.Button = DropdownMenuButton;
DropdownMenu.Label = DropDownMenuLabel;
DropdownMenu.Item = StyledRadixItem;
DropdownMenu.TextItem = DropdownMenuTextItem;
DropdownMenu.Separator = DropdownMenuSeparator;
DropdownMenu.UnstyledItem = DropdownMenuPrimitive.Item;

DropdownMenu.displayName = 'DropdownMenu';
DropdownMenu.Trigger.displayName = 'DropdownMenu.Trigger';
DropdownMenu.SubMenuTriggerItem.displayName = 'DropdownMenu.SubMenuTriggerItem';
DropdownMenu.Content.displayName = 'DropdownMenu.Content';
DropdownMenu.SubContent.displayName = 'DropdownMenu.SubContent';
DropdownMenu.Sub.displayName = 'DropdownMenu.Sub';
DropdownMenu.Button.displayName = 'DropdownMenu.Button';
DropdownMenu.Label.displayName = 'DropdownMenu.Label';
DropdownMenu.Item.displayName = 'DropdownMenu.Item';
DropdownMenu.TextItem.displayName = 'DropdownMenu.TextItem';
DropdownMenu.Separator.displayName = 'DropdownMenu.Separator';
DropdownMenu.UnstyledItem.displayName = 'DropdownMenu.UnstyledItem';

export { DropdownMenu };
