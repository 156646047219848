import { bpWidths } from '../media';

/**
 * Theme tokens reference
 * @property borderStyles    - border-style
 * @property borderWidths    - border-width
 * @property colors          - color, background-color, border-color
 * @property fonts           - font-family
 * @property fontSizes       - font-sizes
 * @property fontWeights     - font-weight
 * @property letterSpacings  - letterSpacing
 * @property lineHeights     - line-height
 * @property radii           - border-radius
 * @property shadows         - box-shadow, text-shadow
 * @property sizes           - width, height, min-width, max-width, min-height, max-height
 * @property space           - margin, padding, grid-gap
 * @property transitions     - transition
 * @property zIndices        - zIndices
 */
export const theme2021 = {
  borderStyles: {},
  borderWidths: {
    borderWidth0: '0px',
    borderWidth1: '1px',
    borderWidth2: '2px',
    borderWidth3: '4px',
  },
  colors: {
    // 2021 Perceptuals
    grayscale0: '#FFFFFF',
    grayscale030: '#FAFAFA',
    grayscale100: '#EFF0F0',
    grayscale200: '#E2E3E3',
    grayscale200_40: 'rgba(226,227,227,0.4)',
    grayscale300: '#C6C7C8',
    grayscale600: '#8D8F91',
    grayscale700: '#656567',
    grayscale800: '#545759',
    grayscale800_40: 'rgba(84,87,89,0.4)',
    grayscale900: '#1B1F23',
    grayscale900_08: 'rgba(27,31,35,0.08)',
    grayscale900_12: 'rgba(27,31,35,0.12)',
    grayscale900_16: 'rgba(27,31,35,0.16)',
    grayscale900_24: 'rgba(27,31,35,0.24)',
    grayscale900_40: 'rgba(27,31,35,0.4)',
    grayscale1000: '#000000',
    grayscale1000_50: 'rgba(0,0,0,0.5)',
    yellow100: '#FFFDE5',
    yellow200: '#FFF8B3',
    yellow300: '#FFF382',
    yellow300_40: 'rgba(255,243,130,0.4)',
    yellow600: '#FFE600',
    yellow700: '#F9D100',
    green100: '#D8EFE4',
    green200: '#9FD6BC',
    green700: '#3AA372',
    green800: '#30855D',
    green900: '#1F573D',
    red100: '#FFD7DE',
    red200: '#FF9CAC',
    red700: '#ED3553',
    red800: '#B3283E',
    creamsicleOrange100: '#FFE1A9',
    aperolOrange100: '#FFD4BF',
    aperolOrange200: '#FFA175',
    aperolOrange700: '#E04800',
    aperolOrange800: '#AD3800',
    hyperlinkBlue700: '#0074E0',
    hyperlinkBlue800: '#005AAD',
    celeryGreen100: '#E2FA9F',
    celeryGreen200: '#BDD185',
    celeryGreen700: '#788554',
    celeryGreen800: '#617030',
    cloudBlue100: '#E3F0F4',
    cloudBlue200: '#82C8D2',
    cloudBlue700: '#55838A',
    cloudBlue800: '#2A4A50',
    cloveBrown100: '#F9F7F0',
    cloveBrown200: '#D1BAB0',
    cloveBrown700: '#AD6848',
    cloveBrown800: '#7F2801',
    lavenderPurple030: '#FBF3FF',
    lavenderPurple100: '#EDC6ED',
    lavenderPurple200: '#C878D1',
    lavenderPurple700: '#834F8A',
    lavenderPurple800: '#58495B',
    steelBlue100: '#E7F2FE',
    steelBlue200: '#CEE5FD',
    steelBlue300: '#B9CEE4',
    steelBlue700: '#67737E',
    steelBlue800: '#3E454C',

    // Functional Tokens
    bgDefault: '$grayscale0',
    bgAccentSubtle: '$grayscale030',
    bgAccent: '$grayscale100',
    bgAccentDark: '$grayscale200',
    bgPlaceholder: '$grayscale200',
    bgPlaceholderAlt: '$grayscale300',
    bgOverlay: '$grayscale1000_50',
    bgTooltip: '$grayscale1000',
    bgSuccessDefault: '$green100',
    bgSuccessAccent: '$green200',
    bgInformationalDefault: '$cloveBrown100',
    bgInformationalAccent: '$cloveBrown200',
    bgCriticalDefault: '$red100',
    bgCriticalAccent: '$red200',
    bgWarningDefault: '$creamsicleOrange100',
    bgWarningAccent: '$aperolOrange200',
    bgGuidanceDefault: '$lavenderPurple030',
    bgGuidanceAccent: '$lavenderPurple700',
    bgDecorative1Default: '$celeryGreen100',
    bgDecorative1Accent: '$celeryGreen200',
    bgDecorative2Default: '$cloudBlue100',
    bgDecorative2Accent: '$cloudBlue200',
    bgDecorative3Default: '$steelBlue100',
    bgDecorative3Accent: '$steelBlue200',
    bgDecorative4Default: '$lavenderPurple100',
    bgDecorative4Accent: '$lavenderPurple200',
    bgActionPrimary: '$yellow300',
    bgActionPrimaryHover: '$yellow600',
    bgActionPrimaryPressed: '$yellow700',
    bgActionPrimaryDisabled: '$yellow300_40',
    bgActionSecondary: '$grayscale200',
    bgActionSecondaryHover: '$grayscale300',
    bgActionSecondaryPressed: '$grayscale600',
    bgActionSecondaryDisabled: '$grayscale200_40',
    bgActionBasic: '$grayscale0',
    bgActionBasicHover: '$grayscale200',
    bgActionBasicPressed: '$grayscale300',
    bgBrand: '$yellow300',
    bgInverted: '$grayscale900',
    bgInvertedDisabled: '$grayscale600',
    bgRow: '$grayscale0',
    bgRowHover: '$grayscale100',
    bgRowPressed: '$grayscale200',
    bgRowSelected: '$grayscale200',
    bgRowSelectedHover: '$grayscale200',
    bgRowSelectedPressed: '$grayscale300',
    bgToggleDefault: '$grayscale0',
    bgToggleHover: '$grayscale200',
    bgTogglePressed: '$grayscale300',
    bgToggleSelected: '$grayscale900',
    bgHighlighted: '$steelBlue200',
    bgGradientMagic:
      'linear-gradient(90deg, $bgDecorative4Default 12.03%, $bgDecorative3Accent 91.25%)',
    bgGradientMagicFallback: '$bgDecorative4Default',
    textDefault: '$grayscale900',
    textSubdued: '$grayscale700',
    textSuccess: '$green800',
    textWarning: '$aperolOrange800',
    textCritical: '$red800',
    textInformational: '$cloveBrown800',
    textHover: '$hyperlinkBlue700',
    textPressed: '$hyperlinkBlue800',
    textInverted: '$grayscale0',
    textDisabled: '$grayscale900_40',
    textDecorative1: '$celeryGreen800',
    textDecorative2: '$cloudBlue800',
    textDecorative3: '$steelBlue800',
    textDecorative4: '$lavenderPurple800',
    textLink: '$grayscale900',
    textSelectedToggle: '$grayscale900',
    iconDefault: '$grayscale900',
    iconSubdued: '$grayscale600',
    iconSuccess: '$green700',
    iconWarning: '$aperolOrange700',
    iconCritical: '$red700',
    iconInfo: '$cloveBrown700',
    iconGuidance: '$lavenderPurple700',
    iconDisabled: '$grayscale900_40',
    iconHovered: '$hyperlinkBlue700',
    iconPressed: '$hyperlinkBlue800',
    iconInverted: '$grayscale0',
    iconDecorative1: '$celeryGreen700',
    iconDecorative2: '$cloudBlue700',
    iconDecorative3: '$steelBlue700',
    iconDecorative4: '$lavenderPurple700',
    borderDefault: '$grayscale200',
    borderLoud: '$grayscale300',
    borderVisualization: '$grayscale600',
    borderInverted: '$grayscale0',
    borderInput: '$grayscale800',
    borderInputHover: '$grayscale1000',
    borderInputSuccess: '$green700',
    borderInputError: '$red700',
    borderInputDisabled: '$grayscale800_40',
    borderActionBasic: '$grayscale800',
    borderActionBasicDisabled: '$grayscale800_40',
    borderFocus: `$grayscale900`,
    borderSelectedToggle: '$grayscale1000',
  },
  fonts: {
    display: 'Ginto Nord',
    body: 'Ginto Normal',
  },
  fontSizes: {
    fontSize1: '0.75rem',
    fontSize2: '0.875rem',
    fontSize3: '1rem',
    fontSize4: '1.25rem',
    fontSize5: '1.5rem',
    fontSize6: '1.75rem',
    fontSize7: '2rem',
  },
  fontWeights: {
    regular: '400',
    bold: '500',
  },
  letterSpacings: {
    letterSpacing0: '0px',
    letterSpacing1: '0.3px',
    letterSpacing2: '0.5px',
  },
  lineHeights: {
    lineHeight1: '1',
    lineHeight2: '1.25',
    lineHeight3: '1.285',
    lineHeight4: '1.333',
    lineHeight5: '1.4',
    lineHeight6: '1.428',
    lineHeight7: '1.5',
  },
  radii: {
    radius1: '4px',
    radius2: '8px',
    radius3: '16px',
    radiusMax: '9999px',
  },
  shadows: {
    focus: '0 0 0 2px $colors$bgDefault, 0 0 0 4px $colors$borderFocus',
    inputFocus: '0 0 0 1px $colors$borderFocus',
    drastic: '0 8px 16px 0 rgba(0, 48, 45, 0.25)',
    shadow1: '0px 4px 12px 0px $colors$grayscale900_08',
    shadow2: '0 4px 16px 0 $colors$grayscale900_12',
    shadow3: '6px 6px 20px 4px $colors$grayscale900_16',
    shadow4: '0 10px 25px 6px $colors$grayscale900_24',
  },
  sizes: {
    size0: '0',
    size1: '4px',
    size2: '8px',
    size3: '12px',
    size4: '16px',
    size5: '20px',
    size6: '24px',
    size7: '28px',
    size8: '32px',
    size9: '36px',
    size10: '40px',
    size11: '44px',
    size12: '48px',
    size13: '52px',
    size14: '56px',
    size15: '60px',
    size16: '64px',

    ...bpWidths,
  },
  space: {
    space0: '0',
    space1: '4px',
    space2: '8px',
    space3: '12px',
    space4: '16px',
    space5: '20px',
    space6: '24px',
    space7: '28px',
    space8: '32px',
    space9: '36px',
    space10: '40px',
    space11: '44px',
    space12: '48px',
    space13: '52px',
    space14: '56px',
    space15: '60px',
    space16: '64px',
  },
  transitions: {},
  zIndices: {
    zIndex1: '2147483647',
    zIndex2: '2147483647',
    zIndex3: '2147483647',
  },
};

export type ThemeManifest = typeof theme2021;

export type PicnicColorsKey = keyof (typeof theme2021)['colors'];
export type PicnicColorsToken = `$${Extract<PicnicColorsKey, string>}`;
export const colors = theme2021.colors;

export type PicnicFontSizesKey = keyof (typeof theme2021)['fontSizes'];
export type PicnicFontSizesToken = `$${Extract<PicnicFontSizesKey, string>}`;

export type PicnicSizesKey = keyof (typeof theme2021)['sizes'];
export type PicnicSizesToken = `$${Extract<PicnicSizesKey, string>}`;
export const sizes = theme2021.sizes;

export type PicnicSpaceKey = keyof (typeof theme2021)['space'];
export type PicnicSpaceToken = `$${Extract<PicnicSpaceKey, string>}`;
export const space = theme2021.space;

type PicnicShadowsKey = keyof (typeof theme2021)['shadows'];
export type PicnicShadowsToken = `$${Extract<PicnicShadowsKey, string>}`;
export const shadows = theme2021.shadows;
