export enum Locale {
  enUS = 'en-US',
  enCA = 'en-CA',
  enGB = 'en-GB',
  enAU = 'en-AU',
  enNZ = 'en-NZ',
  enFR = 'en-FR',
  enBE = 'en-BE',
  enBR = 'en-BR',
  enCH = 'en-CH',
  enDE = 'en-DE',
  enDK = 'en-DK',
  enES = 'en-ES',
  enIT = 'en-IT',
  enMX = 'en-MX',
  enNL = 'en-NL',
  enNO = 'en-NO',
  enPL = 'en-PL',
  enPT = 'en-PT',
  enSE = 'en-SE',
  frFR = 'fr-FR',
  frBE = 'fr-BE',
  frCA = 'fr-CA',
  frCH = 'fr-CH',
  enIE = 'en-IE',
  itIT = 'it-IT',
  nlNL = 'nl-NL',
  ptPT = 'pt-PT',
  ptBR = 'pt-BR',
  esES = 'es-ES',
  esMX = 'es-MX',
  svSE = 'sv-SE',
  deDE = 'de-DE',
  nlBE = 'nl-BE',
  deCH = 'de-CH',
  plPL = 'pl-PL',
  noNO = 'no-NO',
  daDK = 'da-DK',
}

export type LocaleFormatEntry = {
  default: string;
} & Partial<Record<Locale, string>>;

export function isLocale(val: unknown): val is Locale {
  return Object.values(Locale).includes(val as Locale);
}

const localeToName: Record<Locale, LocaleFormatEntry> = {
  [Locale.enUS]: { default: 'English (US)' },
  [Locale.enGB]: { default: 'English (UK)' },
  [Locale.enCA]: { default: 'English (CA)' },
  [Locale.enAU]: { default: 'English (AU)' },
  [Locale.enIE]: { default: 'English (IE)' },
  [Locale.enNZ]: { default: 'English (NZ)' },
  [Locale.enFR]: { default: 'English (FR)' },
  [Locale.enBE]: { default: 'English (BE)' },
  [Locale.enBR]: { default: 'English (BR)' },
  [Locale.enCH]: { default: 'English (CH)' },
  [Locale.enDE]: { default: 'English (DE)' },
  [Locale.enDK]: { default: 'English (DK)' },
  [Locale.enES]: { default: 'English (ES)' },
  [Locale.enIT]: { default: 'English (IT)' },
  [Locale.enMX]: { default: 'English (MX)' },
  [Locale.enNL]: { default: 'English (NL)' },
  [Locale.enNO]: { default: 'English (NO)' },
  [Locale.enPL]: { default: 'English (PL)' },
  [Locale.enPT]: { default: 'English (PT)' },
  [Locale.enSE]: { default: 'English (SE)' },
  [Locale.frFR]: { default: 'French (FR)', [Locale.frFR]: 'Français' },
  [Locale.frBE]: { default: 'French (BE)', [Locale.frBE]: 'Français' },
  [Locale.frCA]: { default: 'French (CA)', [Locale.frCA]: 'Français' },
  [Locale.frCH]: { default: 'French (CH)', [Locale.frCH]: 'Français' },
  [Locale.esES]: { default: 'Spanish (ES)', [Locale.esES]: 'Español' },
  [Locale.esMX]: { default: 'Spanish (MX)', [Locale.esMX]: 'Español' },
  [Locale.itIT]: { default: 'Italian', [Locale.itIT]: 'Italiano' },
  [Locale.nlNL]: { default: 'Dutch (NL)', [Locale.nlNL]: 'Nederlands' },
  [Locale.nlBE]: { default: 'Dutch (BE)', [Locale.nlBE]: 'Vlaams' },
  [Locale.ptPT]: { default: 'Portuguese (PT)', [Locale.ptPT]: 'Português' },
  [Locale.ptBR]: { default: 'Portuguese (BR)', [Locale.ptBR]: 'Português' },
  [Locale.svSE]: { default: 'Swedish', [Locale.svSE]: 'Svenska' },
  [Locale.deDE]: { default: 'German (DE)', [Locale.deDE]: 'Deutsche' },
  [Locale.deCH]: { default: 'German (CH)', [Locale.deCH]: 'Schweizerdeutsch' },
  [Locale.plPL]: { default: 'Polish', [Locale.plPL]: 'Polski' },
  [Locale.noNO]: { default: 'Norwegian', [Locale.noNO]: 'Norsk' },
  [Locale.daDK]: { default: 'Danish', [Locale.daDK]: 'Dansk' },
};

export function getLocaleName(locale: Locale, nameInLocale: Locale = Locale.enUS) {
  const localeNameLabels = localeToName[locale];
  if (localeNameLabels === undefined) {
    throw new Error(`Unsupported locale tag passed: ${locale}`);
  }
  return localeNameLabels[nameInLocale] || localeNameLabels.default;
}
