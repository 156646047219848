import * as PopoverPrimitive from '@radix-ui/react-popover';
import React, { createContext, useContext } from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { MAX_Z_INDEX_VALUE } from '../../utils/z-index';
import { BoxProps } from '../Box';
import { IconButton, Button, ButtonProps } from '../Button';

type PopoverVariants = 'default' | 'guidance';
const VariantContext = createContext<PopoverVariants>('default');
const VariantContextProvider = VariantContext.Provider;
const useVariant = () => {
  return useContext(VariantContext);
};

const StyledArrowPath = styled('path', {
  strokeWidth: '1.5',
  stroke: '$borderLoud',

  variants: {
    variant: {
      default: {
        fill: '$bgDefault',
      },
      guidance: {
        fill: '$lavenderPurple700',
      },
    },
  },
});

const ArrowWithBorder = React.forwardRef((__props, __forwardedRef) => {
  const variant = useVariant();

  return (
    <svg
      width={18}
      height={8}
      viewBox="0 0 30 10"
      preserveAspectRatio="none"
      style={{ marginTop: -1, display: 'block' }}
      data-testid="popover-arrow"
    >
      <StyledArrowPath d="M0,0 L15,10 L30,0" variant={variant} />
    </svg>
  );
});

const StyledContent = styled(PopoverPrimitive.Content, {
  width: '90vw',
  maxWidth: '400px',
  borderRadius: '$radius1',
  border: '1px solid $borderLoud',
  padding: '$space4',
  boxShadow: '$shadow2',
  zIndex: MAX_Z_INDEX_VALUE,

  '&:focus': {
    outline: 'none',
  },

  variants: {
    variant: {
      default: {
        backgroundColor: '$bgDefault',
      },
      guidance: {
        color: '$textInverted',
        backgroundColor: '$lavenderPurple700',
      },
    },
  },
});

const CloseButton = ({ children, ...rest }: ButtonProps) => {
  return (
    <PopoverPrimitive.Close {...rest} asChild>
      <Button>{children}</Button>
    </PopoverPrimitive.Close>
  );
};

const CloseIconButton = React.forwardRef(({ ...props }, __forwardedRef) => {
  const variant = useVariant();

  return (
    <PopoverPrimitive.Close asChild>
      <IconButton
        iconName="X"
        size="small"
        variant={variant === 'guidance' ? 'inverted' : 'subdued'}
        description="Close"
        css={{
          width: '$size7',
          height: '$size7',
          padding: 0,
          float: 'right',
          margin: 'calc($space2 * -1) calc($space2 * -1) $space2 $space2',
        }}
        {...props}
      />
    </PopoverPrimitive.Close>
  );
});

export interface PopoverContentProps extends BoxProps, PopoverPrimitive.PopoverContentProps {
  showCloseButton?: boolean;
  showArrow?: boolean;
}

const PopoverContent = ({
  showCloseButton = true,
  showArrow = true,
  children,
  ...props
}: PopoverContentProps) => {
  const variant = useVariant();

  return (
    <PopoverPrimitive.Portal>
      <StyledContent alignOffset={4} variant={variant} {...props}>
        {showCloseButton && <CloseIconButton />}
        {children}
        {showArrow && (
          <PopoverPrimitive.Arrow asChild offset={8}>
            <ArrowWithBorder />
          </PopoverPrimitive.Arrow>
        )}
      </StyledContent>
    </PopoverPrimitive.Portal>
  );
};

interface PopoverProps {
  children: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  variant?: PopoverVariants;
}

const PopoverComponent = ({
  open,
  defaultOpen,
  children,
  onOpenChange,
  variant = 'default',
}: PopoverProps) => {
  return (
    <VariantContextProvider value={variant}>
      <PopoverPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
        {children}
      </PopoverPrimitive.Root>
    </VariantContextProvider>
  );
};

const PopoverTrigger: React.FC<React.PropsWithChildren<{ children: React.ReactElement }>> = ({
  children,
}) => <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>;

type ComponentType = typeof PopoverComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Trigger: typeof PopoverTrigger & DisplayNamed;
  Anchor: typeof PopoverPrimitive.Anchor & DisplayNamed;
  Content: typeof PopoverContent & DisplayNamed;
  CloseButton: typeof CloseButton & DisplayNamed;
  CloseIconButton: typeof CloseIconButton & DisplayNamed;
}

const Popover = PopoverComponent as CompositeComponent;
Popover.Trigger = PopoverTrigger;
Popover.Anchor = PopoverPrimitive.Anchor;
Popover.Content = PopoverContent;
Popover.CloseButton = CloseButton;
Popover.CloseIconButton = CloseIconButton;

Popover.displayName = 'Popover';
Popover.Trigger.displayName = 'Popover.Trigger';
Popover.Anchor.displayName = 'Popover.Anchor';
Popover.Content.displayName = 'Popover.Content';
Popover.CloseButton.displayName = 'Popover.CloseButton';
Popover.CloseIconButton.displayName = 'Popover.CloseIconButton';

export type { PopoverProps };
export { Popover };
