import * as PopoverPrimitive from '@radix-ui/react-popover';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import {
  DayOfWeekShape,
  DayPickerRangeController,
  DayPickerRangeControllerShape,
  FocusedInputShape,
} from 'react-dates';
// @ts-ignore
import reactDatesDefaultPhrases from 'react-dates/lib/defaultPhrases';

import { PicnicCss, styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { MAX_Z_INDEX_VALUE } from '../../utils/z-index';
import { Box } from '../Box';

import { DatePickerInput } from './DatePickerInput';
import { DateRangePickerControlMonthElement } from './DateRangePickerControlMonthElement';
import { DISPLAY_DATE_FORMAT, MOMENT_ISO_8601_DATE_FORMAT } from './constants';
import { dateRangePickerCssOverrides } from './datepicker-global-css';
import { convertMomentToString, convertStringToMoment } from './utils';

const PU_DATEPICKER_BOX_SHADOW = '$shadow2';
const PU_DATEPICKER_FONT_SIZE = '$fontSize2';
const PU_DATEPICKER_BG_COLOR = '$bgDefault';
const PU_DATEPICKER_BORDER = '1px solid $borderLoud';

const StyledPopover = styled(PopoverPrimitive.Content, {
  backgroundColor: PU_DATEPICKER_BG_COLOR,
  borderRadius: '$radius1',
  border: PU_DATEPICKER_BORDER,
  boxShadow: PU_DATEPICKER_BOX_SHADOW,
  fontSize: PU_DATEPICKER_FONT_SIZE,
  overflow: 'hidden',
  zIndex: MAX_Z_INDEX_VALUE,

  '&:focus': {
    outline: 'none',
  },
});

export type DateRangeChangeType = (dates: {
  startDate: string | null;
  endDate: string | null;
}) => void;

interface DateRangePickerProps extends Pick<DayPickerRangeControllerShape, 'numberOfMonths'> {
  css?: PicnicCss;
  startDate: string | null;
  endDate: string | null;
  minExpectedYear?: number;
  maxExpectedYear?: number;
  isDayBlocked?: (isoDate: string) => boolean;
  isDayHighlighted?: (isoDate: string) => boolean;
  onChange?: DateRangeChangeType;
  onDateSelect?: DateRangeChangeType;
  minimumNights?: number;
  firstDayOfWeek?: DayOfWeekShape;
}

type DateRangePickerControlProps = DateRangePickerProps &
  Partial<
    Pick<
      DayPickerRangeControllerShape,
      | 'firstDayOfWeek'
      | 'noNavNextButton'
      | 'noNavPrevButton'
      | 'onPrevMonthClick'
      | 'onNextMonthClick'
      | 'monthFormat'
      | 'dayAriaLabelFormat'
      | 'renderMonthElement'
    >
  >;

type OptionalMoment = Moment | null;

const DateRangePickerControl = ({
  css = {},
  startDate: startDateProp,
  endDate: endDateProp,
  minExpectedYear,
  maxExpectedYear,
  isDayBlocked,
  isDayHighlighted,
  onChange,
  onDateSelect,
  minimumNights,
  firstDayOfWeek = 1,
  numberOfMonths = 2,
  ...rest
}: DateRangePickerControlProps) => {
  const [selectType, setSelectionType] = React.useState<'startDate' | 'endDate'>('startDate');
  const [{ startDate, endDate }, setDates] = React.useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: startDateProp || null,
    endDate: endDateProp || null,
  });

  React.useEffect(() => {
    setSelectionType('startDate');
    setDates({ startDate: startDateProp || null, endDate: endDateProp || null });
  }, [startDateProp, endDateProp]);

  // This date is coming from the react-dates package itself
  const handleIsDayBlocked = (date: Moment) => {
    const dateToIso = convertMomentToString(moment(date));
    // Received date should only throw an error if something is wrong with the react-dates package itself
    if (dateToIso === null) throw new Error('Received invalid date');

    if (isDayBlocked) {
      return isDayBlocked(dateToIso);
    }

    return false;
  };

  // This date is coming from the react-dates package itself
  const handleIsDayHighlighted = (date: Moment) => {
    const dateToIso = convertMomentToString(moment(date));
    // Received date should only throw an error if something is wrong with the react-dates package itself
    if (dateToIso === null) throw new Error('Received invalid date');

    if (isDayHighlighted) {
      return isDayHighlighted(dateToIso);
    }

    return false;
  };

  const startDateMoment = convertStringToMoment(startDate || null);
  const endDateMoment = convertStringToMoment(endDate || null);
  const handleDateRangeChange = ({
    startDate: changedStartMoment,
    endDate: changedEndMoment,
  }: {
    startDate: OptionalMoment;
    endDate: OptionalMoment;
  }) => {
    const changedStartDate = changedStartMoment
      ? changedStartMoment.format(MOMENT_ISO_8601_DATE_FORMAT)
      : null;
    const changedEndDate = changedEndMoment
      ? changedEndMoment.format(MOMENT_ISO_8601_DATE_FORMAT)
      : null;

    if (onDateSelect) {
      onDateSelect({ startDate: changedStartDate, endDate: changedEndDate });
    }

    const isChangedStartDateBeforeStartDate = changedStartMoment
      ? changedStartMoment.isBefore(startDate)
      : false;
    const isChangedEndDateSameOrAfterStartDate = changedEndMoment
      ? changedEndMoment.isSameOrAfter(startDate)
      : false;
    const startDateIsUnchanged = changedStartMoment ? changedStartMoment.isSame(startDate) : false;
    if (!startDateIsUnchanged || isChangedStartDateBeforeStartDate) {
      setDates({ startDate: changedStartDate, endDate: null });
    } else if (isChangedEndDateSameOrAfterStartDate) {
      setDates({ startDate, endDate: changedEndDate });

      if (onChange && selectType === 'endDate') {
        onChange({
          startDate: changedStartDate,
          endDate: changedEndDate,
        });
      }
    }
  };

  const handleFocusChange = (focus: FocusedInputShape | null) => {
    setSelectionType(focus || 'startDate');
  };

  return (
    <Box
      css={{
        ...dateRangePickerCssOverrides,
        ...css,
      }}
    >
      <DayPickerRangeController
        transitionDuration={0}
        minimumNights={minimumNights || 0}
        numberOfMonths={numberOfMonths}
        startDate={startDateMoment}
        endDate={endDateMoment}
        focusedInput={selectType}
        onDatesChange={handleDateRangeChange}
        onFocusChange={handleFocusChange}
        isDayBlocked={isDayBlocked && handleIsDayBlocked}
        isDayHighlighted={isDayHighlighted && handleIsDayHighlighted}
        renderMonthElement={(props) => (
          <DateRangePickerControlMonthElement
            {...props}
            minExpectedYear={minExpectedYear}
            maxExpectedYear={maxExpectedYear}
          />
        )}
        initialVisibleMonth={() => moment(startDate || endDate || moment())}
        hideKeyboardShortcutsPanel
        horizontalMonthPadding={24}
        firstDayOfWeek={firstDayOfWeek}
        phrases={{
          ...reactDatesDefaultPhrases,
          chooseAvailableStartDate: ({ date }: { date: string }) =>
            `Choose ${date} as your start date.`,
          chooseAvailableEndDate: ({ date }: { date: string }) =>
            `Choose ${date} as your end date.`,
        }}
        {...rest}
      />
    </Box>
  );
};

type DateRangePickerPopoverProps = React.ComponentProps<typeof PopoverPrimitive.Content>;

const DateRangePickerPopover = ({ children, ...rest }: DateRangePickerPopoverProps) => {
  return (
    <PopoverPrimitive.Portal>
      <StyledPopover align="start" aria-label="date range picker" {...rest}>
        {children}
      </StyledPopover>
    </PopoverPrimitive.Portal>
  );
};

type PopoverContentProps = Pick<
  PopoverPrimitive.PopoverContentProps,
  'side' | 'sideOffset' | 'align' | 'alignOffset'
>;

interface DateRangePickerComponentProps
  extends PopoverPrimitive.PopoverProps,
    DateRangePickerProps,
    PopoverContentProps {
  defaultOpen?: boolean;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'normal';
  state?: 'error' | 'normal';
  title?: string;
}

export const formatDateRange = (
  startDate: string | null,
  endDate: string | null,
  isEndDateOptional = false,
  endDateText = 'Never expires'
) => {
  if (isEndDateOptional && !endDate) {
    return [startDate ? moment(startDate).format(DISPLAY_DATE_FORMAT) : '', endDateText].join(
      ' - '
    );
  } else if (startDate && endDate) {
    return [
      startDate ? moment(startDate).format(DISPLAY_DATE_FORMAT) : '',
      endDate ? moment(endDate).format(DISPLAY_DATE_FORMAT) : '',
    ].join(' - ');
  }

  return '';
};

export const DateRangePickerComponent = ({
  css = {},
  startDate,
  endDate,
  placeholder,
  size = 'normal',
  state = 'normal',
  defaultOpen,
  disabled = false,
  isDayBlocked,
  isDayHighlighted,
  side,
  sideOffset,
  align = 'start',
  alignOffset,
  onChange,
  minimumNights,
  title,
  minExpectedYear,
  maxExpectedYear,
  firstDayOfWeek = 1,
}: DateRangePickerComponentProps) => {
  const [open, setOpen] = React.useState(defaultOpen);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setOpen(false);
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const displayStartDate = formatDateRange(startDate, endDate);

  const dialogToggled = (isOpen: boolean) => {
    if (!disabled) {
      setOpen(isOpen);
    }
  };

  return (
    <PopoverPrimitive.Root open={open} onOpenChange={dialogToggled}>
      <PopoverPrimitive.Anchor asChild>
        <PopoverPrimitive.Trigger asChild>
          <DatePickerInput
            css={css}
            disabled={disabled}
            size={size}
            state={state}
            placeholder={placeholder}
            value={displayStartDate}
            description="Open date range picker"
            inputGroupRef={inputRef}
            title={title}
            data-testid="picnic-date-range-picker-input"
          />
        </PopoverPrimitive.Trigger>
      </PopoverPrimitive.Anchor>
      <DateRangePickerPopover
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
      >
        <DateRangePickerControl
          key={String(open)}
          startDate={startDate}
          endDate={endDate}
          minExpectedYear={minExpectedYear}
          maxExpectedYear={maxExpectedYear}
          onChange={(changedDates) => {
            setOpen(false);
            if (onChange) {
              onChange(changedDates);
            }
          }}
          isDayBlocked={isDayBlocked}
          isDayHighlighted={isDayHighlighted}
          firstDayOfWeek={firstDayOfWeek}
          minimumNights={minimumNights}
        />
      </DateRangePickerPopover>
    </PopoverPrimitive.Root>
  );
};

interface DateRangePickerRootProps extends PopoverPrimitive.PopoverProps {
  children: ReactNode;
}

export const DateRangePickerRoot = ({
  open,
  defaultOpen,
  children,
  onOpenChange,
}: DateRangePickerRootProps) => {
  return (
    <PopoverPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      {children}
    </PopoverPrimitive.Root>
  );
};

const DateRangePickerTrigger: React.FC<
  React.PropsWithChildren<{ children: React.ReactElement }>
> = ({ children }) => <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>;

const DateRangePickerAnchor: React.FC<
  React.PropsWithChildren<{ children: React.ReactElement }>
> = ({ children }) => <PopoverPrimitive.Anchor asChild>{children}</PopoverPrimitive.Anchor>;

type ComponentType = typeof DateRangePickerComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Root: typeof DateRangePickerRoot & DisplayNamed;
  Anchor: typeof DateRangePickerAnchor & DisplayNamed;
  Trigger: typeof DateRangePickerTrigger & DisplayNamed;
  Input: typeof DatePickerInput & DisplayNamed;
  Popover: typeof DateRangePickerPopover & DisplayNamed;
  Control: typeof DateRangePickerControl & DisplayNamed;
}

const DateRangePicker = DateRangePickerComponent as CompositeComponent;
DateRangePicker.Root = DateRangePickerRoot;
DateRangePicker.Anchor = DateRangePickerAnchor;
DateRangePicker.Trigger = DateRangePickerTrigger;
DateRangePicker.Input = DatePickerInput;
DateRangePicker.Popover = DateRangePickerPopover;
DateRangePicker.Control = DateRangePickerControl;

DateRangePicker.displayName = 'DateRangePicker';
DateRangePicker.Root.displayName = 'DateRangePicker.Root';
DateRangePicker.Trigger.displayName = 'DateRangePicker.Anchor';
DateRangePicker.Trigger.displayName = 'DateRangePicker.Trigger';
DateRangePicker.Input.displayName = 'DateRangePicker.Input';
DateRangePicker.Popover.displayName = 'DateRangePicker.Popover';
DateRangePicker.Control.displayName = 'DateRangePicker.Control';

export { DateRangePicker };
